


import { Component, Vue } from "vue-property-decorator";
import { LogIn } from "@/models/login.model";
import Axios from "axios";
import { BASE_API_URL, setInstanceVariables } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import { PreRequisiteModel } from "../../../models/pre-requisite.model";
import { identifyUserSession } from '@/logrocket';

@Component({
  components: {
    PreRequisiteHeader
  }
})
export default class LoginComponent extends Vue {
  public login = new LogIn();
  public generalErrMessage: any = null;
  public generalSuccessMessage: any = null;
  public mode = "login";
  public duplicateEmail = false;
  public successInfo: any = {};
  public email = null;
  public errorMsg = null;
  public errorType = null;
  public inProcess = false;
  public userToResendLink = null;
  public errorMessage = null;
  public errorTypes = null;
  public password = null;
  public preRequisite: any = new PreRequisiteModel();

  public async validateAndLogin(evt, rememberMeToken = '') {
    this.$store.state.wemloLoader = true;
    try {
      this.inProcess = true;
      this.generalErrMessage = null;
      this.generalSuccessMessage = null;
      this.errorMsg = null;
      this.errorType = null;

      let form = {}
      
      if (rememberMeToken) {
        /**
         * Login with remember me token
         */
        form = {
          rememberMeToken: rememberMeToken,
        };
      } else {
        /**
         * Run form validation
         */
        let formValid = await this.$validator.validateAll();
        if (!formValid) {
          this.inProcess = false;
          this.$store.state.wemloLoader = false;
          return;
        }

        /**
         * Normal login
         */
        form = {
          email: this.login.email.toLowerCase(),
          password: this.login.password,
          isRememberMe: this.login.isRememberMe,
        };
      }

      /**
       * Submit login form
       */
      const response = await Axios.post(BASE_API_URL + "authentication/login", form);

      if (response.data.token) {
        this.saveRememberMeData(response.data.rememberMeToken);

        /**
         * Configure LogRocket to capture basic user information.
         */
        identifyUserSession(response.data);

        /**
         * Set all the user's information to vuex data store
         */
        await this.$store.dispatch("setSession", response.data);
        /**
         * Set user's id to instance to make it easily accessible
         */
        await setInstanceVariables();
        /**
         * Dispatch the information of the user which holds the access rights on to components
         * after onboarding/login.
         */
        await this.$store.dispatch("setSidenavLinks", response.data);

        /**
         * Check if we stored original link that has been hit by a user. If yes, redirect to it.
         */
        const originalSessionUrl = sessionStorage.getItem('original_url');

        if (originalSessionUrl) {
          sessionStorage.removeItem('original_url');
          this.$router.push(originalSessionUrl);
          this.inProcess = false;
          this.$store.state.wemloLoader = false;
          return;
        }

        if (response.data.userInfo) {
          if (response.data.type == "Broker") {
            /**
             * We need to replace because we don't want the user to come back to login using the
             * browser's back arrow
             */
            this.$router.replace({
              name:
                response.data.userInfo.completedPreRequisites == 'onboarding'
                  ? "BrokerDashBoard"
                  : "Prerequisites"
            });
          } else if (response.data.type == "MLO") {
            this.$router.replace({
              name:
                response.data.userInfo.completedPreRequisites == 'agreement'
                  ? "MLODashBoard"
                  : "MLOPrerequisites"
            });
            // this.$router.replace({name: "MLODashBoard" });
          } else if (response.data.type == "Admin Staff") {
            this.$router.replace({ name: "AdminStaffDashBoard" });
          } else if (response.data.type == "Wemlo Processor") {
            this.$router.replace({ name: "ProcessorDashBoard" });
          } else if (response.data.type === "Super Admin") {
            this.$router.replace({ name: "SuperAdminDashBoard" });
          } else {
            this.$router.replace(response.data.borrowerRoute);
          }
        } else {
          if (response.data.type == "Super Admin") {
            this.$router.push({ name: "SuperAdminDashBoard" });
          } else {
            this.$router.push({ name: "Prerequisites" });
          }
        }
      }
    } catch (error) {
      const errorData = error.response ? error.response.data : {};

      if (errorData.status) {
        if (errorData.type === "token") {
          this.removeRememberMeData();
        }

        if (errorData.type === "verification") {
          this.userToResendLink = errorData.user;
        }

        this.errorMsg = errorData.message;
        this.errorType = errorData.type;
        if (errorData.isAccountLocked) this.$modal.show("lockedAccModal");
      }
    } finally {
      this.inProcess = false;
      this.$store.state.wemloLoader = false;
    }
  }

  public async resendVerificationLink() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "authentication/resendEmailVerificationLink",
        {
          userInfo: [this.userToResendLink],
          userType: this.userToResendLink.userType
        }
      );
      this.errorMsg = "";
      this.errorType = "";
      this.generalSuccessMessage = response.data.message;
      this.$snotify.success("Verification link has been sent to your email.");
    } catch (error) {}
  }

  public saveRememberMeData(rememberMeToken: string) {
    if (rememberMeToken) {
      localStorage.setItem("rememberMeToken", rememberMeToken);
    } else {
      this.removeRememberMeData();
    }
  }

  public removeRememberMeData() {
    localStorage.removeItem("rememberMeToken");
  }

  public async verifyRememberMeToken() {
    const rememberMeToken = localStorage.rememberMeToken;
    
    if (rememberMeToken) {
      this.validateAndLogin(null, rememberMeToken);
    }
  }

  /**
   *  verify email token when login after email changed
   */
  public async verifyEmailToken(token) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "authentication/verifyEmailToken",
        { token: token }
      );
      if (response.data.status == "tokenExpired") {
        this.$snotify.error("Token has been expired");
        this.$router.replace({ name: "SignUp" });
      } else {
        localStorage.clear();
        this.login = new LogIn();
        this.login.email = response.data.email;
      }
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    /**
     *  if user login after email changed then verify token
     *  and display prefilled email in login page
     */

    this.$modal.hide('reAuthenticationModal');

    if (this.$route.query && this.$route.query.email_updated) {
      this.verifyEmailToken(this.$route.query.email_updated);
    } else {
      this.verifyRememberMeToken();
    }
  }
}
